<template>

  <div
    class="app-buoy"
    role="content">

    <div id="map">
    </div>

    <span class="sea-label">黄海
    </span>

    <!-- <div id="mask">
    </div> -->

    <ul class="latitude">

      <li v-for="(item, index) in latitude"
        :key="index">{{ item }}
      </li>

    </ul>

    <ul class="longitude">

      <li v-for="(item, index) in longitude"
        :key="index">{{ item }}
      </li>

    </ul>

    <div class="toolbar">

      <label for="longitude">经度:&emsp;
      </label>

      <input v-model="form.longitude"
        name="longitude"
        type="number">

      <label for="longitude">纬度:&emsp;
      </label>

      <input v-model="form.latitude"
        name="latitude"
        type="number">

      <label for="longitude">名称:&emsp;
      </label>

      <input v-model="form.name"
        name="name"
        type="text">

      <button @click="setMarker()">标记
      </button>

    </div>

  </div>

</template>

<script>
import 'maptalks/dist/maptalks.css'
import * as maptalks from 'maptalks'

export default {
  name: 'app-buoy',
  data () {
    return {
      base: null,
      form: {
        latitude: 36.720231,
        longitude: 129.283294,
        name: '浮标一号'
      },
      latitude: [
        '21°N', '22°N', '23°N', '24°N'
      ],
      longitude: [
        '121°E', '122°E', '123°E', '124°E', '125°E'
      ],
      map: null,
      marker: null,
      overlay: null
    }
  },
  mounted () {
    this.initMap()
    // this.setMarker()
    this.setMarkerMultiple()
  },
  methods: {
    initMap () {
      this.map = new maptalks.Map('map', {
        attribution: '',
        bearing: 0,
        center: [123.283294, 23.720231],
        zoom: 10
      })

      this.base = new maptalks.TileLayer('image', {
        urlTemplate: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        subdomains: ['a', 'b', 'c', 'd']
      })
      this.map.setBaseLayer(this.base)

      this.overlay = new maptalks.VectorLayer('buoy', {
        zIndex: 10
      })
      this.overlay.addTo(this.map)
    },
    setMarker () {
      this.overlay.clear()
      this.marker = null

      this.marker = new maptalks.Marker([
        this.form.longitude,
        this.form.latitude
      ], {
        properties: {
          name: this.form.name
        },
        symbol: [
          {
            markerFile: '/img/metadata/buoy.png',
            markerWidth: 30,
            markerHeight: 30,
            markerDx: 0,
            markerDy: 0,
            markerOpacity: 1
          },
          {
            textFaceName: 'sans-serif',
            textName: '{name}',
            textWeight: 'normal',
            textStyle: 'normal',
            textSize: 20,
            textFont: null,
            textFill: 'black',
            textOpacity: 1,
            textHaloFill: 'rgba(255, 255, 255, 0.7)',
            textHaloRadius: 1,
            textWrapWidth: null,
            textWrapCharacter: '\n',
            textLineSpacing: 0,
            textDx: 0,
            textDy: 20,
            textHorizontalAlignment: 'middle',
            textVerticalAlignment: 'middle',
            textAlign: 'center'
          }
        ]
      })
      this.marker.addTo(this.overlay)

      const latInt = Math.round(this.form.latitude)
      const lngInt = Math.round(this.form.longitude)
      const latDir = (latInt > 0 || latInt > 90) ? 'N' : 'S'
      const lngDir = (lngInt > 180 || lngInt < 0) ? 'W' : 'E'
      this.latitude = [
        `${latInt + 2}°${latDir}`,
        `${latInt + 1}°${latDir}`,
        `${latInt}°${latDir}`,
        `${latInt - 1}°${latDir}`
      ]
      this.longitude = [
        `${lngInt - 2}°${lngDir}`,
        `${lngInt - 1}°${lngDir}`,
        `${lngInt}°${lngDir}`,
        `${lngInt + 1}°${lngDir}`,
        `${lngInt + 2}°${lngDir}`
      ]

      this.map.fitExtent(
        new maptalks.Extent(
          lngInt - 2,
          latInt - 1,
          lngInt + 2,
          latInt + 2
        )
      )
    },
    setMarkerMultiple () {
      this.overlay.clear()

      const list = [
        {
          name: '1号浮标',
          x: 122.75033333333333,
          y: 38.75966666666667
        },
        {
          name: '2号浮标',
          x: 123.008,
          y: 39.06666666666667
        },
        {
          name: '3号浮标',
          x: 122.59166666666667,
          y: 39.06633333333333
        },
        {
          name: '4号浮标',
          x: 123.10016666666667,
          y: 39.266666666666666
        },
        {
          name: '5号浮标',
          x: 122.74966666666667,
          y: 38.95816666666666
        }
      ]
      const center = {
        x: 0,
        y: 0
      }

      list.forEach((item) => {
        const marker = new maptalks.Marker([
          item.x,
          item.y
        ], {
          properties: {
            name: item.name
          },
          symbol: [
            {
              markerFile: '/img/metadata/buoy.png',
              markerWidth: 15,
              markerHeight: 15,
              markerDx: 0,
              markerDy: 0,
              markerOpacity: 1
            },
            {
              textFaceName: 'sans-serif',
              textName: '{name}',
              textWeight: 'normal',
              textStyle: 'normal',
              textSize: 14,
              textFont: null,
              textFill: 'white',
              textOpacity: 1,
              textHaloFill: 'rgba(255, 255, 255, 0.7)',
              textHaloRadius: 0,
              textWrapWidth: null,
              textWrapCharacter: '\n',
              textLineSpacing: 0,
              textDx: -5,
              textDy: 10,
              textHorizontalAlignment: 'middle',
              textVerticalAlignment: 'middle',
              textAlign: 'center'
            }
          ]
        })
        marker.addTo(this.overlay)

        center.x += item.x / list.length
        center.y += item.y / list.length
      })

      const latInt = Math.round(center.y)
      const lngInt = Math.round(center.x)
      const latDir = (latInt > 0 || latInt > 90) ? 'N' : 'S'
      const lngDir = (lngInt > 180 || lngInt < 0) ? 'W' : 'E'
      this.latitude = [
        `${latInt + 0}°30′${latDir}`,
        `${latInt + 0}°15′${latDir}`,
        `${latInt}°${latDir}`,
        `${latInt - 1}°45′${latDir}`
      ]
      this.longitude = [
        `${lngInt - 1}°30′${lngDir}`,
        `${lngInt - 1}°45′${lngDir}`,
        `${lngInt}°${lngDir}`,
        `${lngInt + 0}°15′${lngDir}`,
        `${lngInt + 0}°30′${lngDir}`
      ]

      this.map.fitExtent(
        new maptalks.Extent(
          lngInt - 0.5,
          latInt - 0.25,
          lngInt + 0.5,
          latInt + 0.5
        )
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.app-buoy {
  height: 500px;
  position: relative;

  #map {
    background-color: black;
    height: 300px;
    position: absolute;
    right: 450px;
    top: 100px;
    width: 400px;
    z-index: 2;
  }
  #mask {
    height: 300px;
    position: absolute;
    right: 450px;
    top: 100px;
    width: 400px;
    z-index: 3;
  }
  .sea-label {
    color: white;
    position: absolute;
    right: 500px;
    top: 250px;
    z-index: 3;
  }
}

.latitude {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding: {
    bottom: 50px;
    left: 0px;
    top: 50px;
  };
  position: absolute;
  right: 865px;
  top: 0px;
  z-index: 1;

  li {
    box-sizing: border-box;
    line-height: 100px;
    list-style: none;
    position: relative;
  }
  li:after {
    border: {
      top: {
        color: black;
        style: solid;
        width: 1px;
      };
    };
    box-sizing: border-box;
    content: ' ';
    height: 100px;
    position: absolute;
    right: -35px;
    top: 50px;
    width: 25px;
  }
  li:last-child:after {
    top: 49px;
  }
}
.longitude {
  bottom: 65px;
  display: flex;
  flex-direction: row;
  left: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding: {
    left: 300px;
    right: 400px;
  };
  position: absolute;
  text-align: center;
  z-index: 1;

  li {
    list-style: none;
    position: relative;
    width: 100px;
  }
  li:after {
    border: {
      left: {
        color: black;
        style: solid;
        width: 1px;
      };
    };
    box-sizing: border-box;
    content: ' ';
    height: 25px;
    position: absolute;
    right: -50px;
    top: -35px;
    width: 100px;
  }
  li:last-child:after {
    right: -49px;
  }
}

.toolbar {
  text-align: left;

  input {
    margin: {
      right: 20px;
    };
    outline: none;
    padding: {
      left: 20px;
    };
  }
}
</style>
